import {
  Hr,
  CardHeading,
  CardSubHeading,
  CardText,
  CardRow,
} from "../../styles/CardStyles";
import { Stack } from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";
import { IUserPoolData, OwnerCard } from "./PoolPage";
import { STAKING_POOL_CONFIG } from "../../config";
import { Contract } from "@ethersproject/contracts";
import { ethers } from "ethers/lib.esm";
import poolAbi from "../../config/abi/pool.json";
import { Pool } from "../../config/abi/types";
import formatWeiToEth from "../../utils/formatWeiToEth";
import ConnectWalletBtn from "../../components/ConnectWalletBtn";
import { LoadingButton } from "@mui/lab";
import CopyToClipboard from "../../components/CopyToClipboard";

interface IStakingCard {
  account: string | null | undefined;
  userPoolData: IUserPoolData;
  pendingTxn: boolean;
  setPendingTxn: Dispatch<SetStateAction<boolean>>;
  getStakingPoolUserValues: (user: string) => Promise<void>;
  handleConnectWalletModalOpen: () => void;
  proof: string[] | [];
  userIsWhitelisted: boolean;
}

const RewardCard: FC<IStakingCard> = ({
  account,
  userPoolData,
  pendingTxn,
  setPendingTxn,
  getStakingPoolUserValues,
  handleConnectWalletModalOpen,
  proof,
  userIsWhitelisted,
}) => {
  const handleHarvest = async () => {
    if (!account || !userPoolData) return;
    setPendingTxn(() => true);
    try {
      const provider = new ethers.providers.Web3Provider(
        // @ts-ignore
        (window as WindowChain).ethereum
      );
      const signer = provider.getSigner();
      const poolContract = new Contract(
        STAKING_POOL_CONFIG.id,
        poolAbi,
        signer
      ) as Pool;
      const depositTx = await poolContract.deposit("0", proof);
      await depositTx.wait();
      await getStakingPoolUserValues(account);
      setPendingTxn(() => false);
    } catch (error) {
      setPendingTxn(() => false);
      console.error("Error in handleApprove: ", error);
    }
  };

  return (
    <OwnerCard>
      <CardHeading>Reward Info</CardHeading>
      <Hr />
      <Stack>
        <Stack
          direction={"row"}
          display={"flex"}
          justifyContent={"space-around"}
          alignItems={"center"}
          marginBottom={"10px"}
          columnGap={1}
        >
          <Stack direction={"row"}>
            <img
              src={`${STAKING_POOL_CONFIG.rewardToken.tokenURL}`}
              alt={`${STAKING_POOL_CONFIG.rewardToken.name} token`}
              width={"30px"}
              height={"30px"}
            />

            <Stack rowGap={0.5} marginLeft={"5px"}>
              <CardText>{STAKING_POOL_CONFIG.rewardToken.name}</CardText>
              <CardSubHeading>
                {STAKING_POOL_CONFIG.rewardToken.symbol}
              </CardSubHeading>
            </Stack>
          </Stack>

          <Stack
            display={"flex"}
            alignItems={"center"}
            fontSize={"18px"}
            fontWeight={600}
            direction={"row"}
          >
            <CardSubHeading>
              {`${STAKING_POOL_CONFIG.rewardToken.address.slice(
                0,
                4
              )}...${STAKING_POOL_CONFIG.rewardToken.address.slice(
                STAKING_POOL_CONFIG.rewardToken.address.length - 4
              )}`}
            </CardSubHeading>
            <CopyToClipboard toCopy={STAKING_POOL_CONFIG.rewardToken.address} />
          </Stack>
        </Stack>
        {account ? (
          <>
            <CardRow style={{ marginBottom: "20px" }}>
              <CardSubHeading>
                {STAKING_POOL_CONFIG.rewardToken.symbol} earned
              </CardSubHeading>
              <CardText>
                {formatWeiToEth(
                  userPoolData?.pendingReward ?? "1",
                  Number(STAKING_POOL_CONFIG.rewardToken.decimals),
                  3
                )}
              </CardText>
            </CardRow>
            <CardRow style={{ opacity: "0" }}>
              <CardSubHeading>Block reward</CardSubHeading>
              <CardText>
                {formatWeiToEth(
                  STAKING_POOL_CONFIG.rewardToken.rewardsPerToken,
                  Number(STAKING_POOL_CONFIG.rewardToken.decimals),
                  6
                )}
              </CardText>
            </CardRow>
            <LoadingButton
              variant={"outlined"}
              onClick={handleHarvest}
              fullWidth
              loading={pendingTxn}
              disabled={!userIsWhitelisted}
            >
              Harvest
            </LoadingButton>
          </>
        ) : (
          <ConnectWalletBtn
            handleConnectWalletModalOpen={handleConnectWalletModalOpen}
          />
        )}
      </Stack>
    </OwnerCard>
  );
};

export default RewardCard;
