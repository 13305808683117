import React from "react";
import { CardSubHeading, CardText } from "../styles/CardStyles";
import { Button, Stack } from "@mui/material";
import { STAKING_POOL_CONFIG } from "../config";
import { ethers } from "ethers";
import { Contract } from "@ethersproject/contracts";
import poolAbi from "../config/abi/pool.json";
import { Pool } from "../config/abi/types";
import { OwnerCard } from "../views/StakingPool/PoolPage";

const RescueFunds = ({
  account,
  pendingTxn,
  setPendingTxn,
  handleConnectWalletModalOpen,
  owner,
}: {
  account: string;
  pendingTxn: boolean;
  setPendingTxn: React.Dispatch<React.SetStateAction<boolean>>;
  handleConnectWalletModalOpen: () => void;
  owner: string;
}) => {
  const handleRescueFunds = async () => {
    setPendingTxn(true);
    try {
      const provider = new ethers.providers.Web3Provider(
        // @ts-ignore
        (window as WindowChain).ethereum
      );
      const signer = provider.getSigner();
      const poolContract = (await new Contract(
        STAKING_POOL_CONFIG.id,
        poolAbi,
        signer
      )) as Pool;

      const txn = await poolContract.rescueFunds(
        STAKING_POOL_CONFIG.rewardToken.address,
        owner
      );

      txn.wait();
      setPendingTxn(false);
    } catch (err) {
      setPendingTxn(false);
      console.error("Error while rescuing funds: ", err);
    }
  };

  return (
    <OwnerCard>
      <Stack rowGap={3}>
        <CardText>Rescue Funds</CardText>
        {account && (
          <Stack direction={"row"} justifyContent={"center"}>
            <CardSubHeading>
              This will remove all the reward tokens from the Staking Pool
              contract
            </CardSubHeading>
          </Stack>
        )}
        {account ? (
          <Button
            variant={"contained"}
            disabled={pendingTxn}
            onClick={handleRescueFunds}
          >
            Rescue Funds
          </Button>
        ) : (
          <Button variant={"outlined"} onClick={handleConnectWalletModalOpen}>
            Connect to Wallet
          </Button>
        )}
      </Stack>
    </OwnerCard>
  );
};

export default RescueFunds;
