import { BigNumber } from "bignumber.js";

export const BIG_10 = new BigNumber(10);

const formatWeiToEth = (
  amount: string,
  decimals = 18,
  roundOffDecimals = 3
) => {
  return new BigNumber(amount)
    .dividedBy(BIG_10.pow(decimals))
    .toFixed(roundOffDecimals);
};

export default formatWeiToEth;
