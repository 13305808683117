import { Stack } from "@mui/material";
import { CardSubHeading, CardText } from "../styles/CardStyles";
import { STAKING_POOL_CONFIG } from "../config";
import { useEffect, useState } from "react";
import { getERC20Contract } from "../utils/contractHelpers";
import BigNumber from "bignumber.js";
import { BIG_10 } from "../utils/formatWeiToEth";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
`;

const RewardTokensRemaining = () => {
  const [remainingRewardTokens, setRemainingRewardTokens] = useState("0");

  const getRewardTokensRemaining = async () => {
    const erc20Contract = getERC20Contract(
      STAKING_POOL_CONFIG.rewardToken.address
    );
    const balanceOfRewardTokens = await erc20Contract.balanceOf(
      STAKING_POOL_CONFIG.id
    );
    const balanceOfRewardTokensInEth = new BigNumber(
      balanceOfRewardTokens.toString()
    )
      .dividedBy(BIG_10.pow(STAKING_POOL_CONFIG.rewardToken.decimals))
      .toFixed();

    setRemainingRewardTokens(() => balanceOfRewardTokensInEth);
  };

  useEffect(() => {
    getRewardTokensRemaining().catch((e) =>
      console.log("Error in getRewardTokensRemaining: ", e)
    );
  }, []);

  return (
    <Container>
      <Stack direction={"row"} justifyContent={"center"}>
        <CardSubHeading>
          Reward tokens remaining in the contract:{" "}
        </CardSubHeading>
        <CardText style={{ margin: "0 8px" }}>
          {Number(remainingRewardTokens).toFixed(3)}{" "}
          {STAKING_POOL_CONFIG.rewardToken.symbol}
        </CardText>
      </Stack>
    </Container>
  );
};

export default RewardTokensRemaining;
