import erc20Abi from "../config/abi/erc20.json";
import poolAbi from "../config/abi/pool.json";
import simpleRpcProvider from "./defaultProvider";
import type { Signer } from "@ethersproject/abstract-signer";
import type { Provider } from "@ethersproject/providers";
import { Contract } from "@ethersproject/contracts";
import { Erc20, Pool } from "../config/abi/types";

export const getContract = (
  abi: any,
  address: string,
  signer?: Signer | Provider
) => {
  const signerOrProvider = signer ?? simpleRpcProvider;
  return new Contract(address, abi, signerOrProvider);
};

export const getERC20Contract = (
  address: string,
  signer?: Signer | Provider
) => {
  return getContract(erc20Abi, address, signer) as Erc20;
};

export const getPoolContract = (
  address: string,
  signer?: Signer | Provider
) => {
  return getContract(poolAbi, address, signer) as Pool;
};
