import { ChangeEvent, Dispatch, FC, SetStateAction } from "react";
import { Card, CardHeading } from "../styles/CardStyles";
import { Button, Modal, TextField } from "@mui/material";
import styled from "styled-components";
import { LoadingButton } from "@mui/lab";

interface IStyledModal {
  heading: string;
  open: boolean;
  handleClose: () => void;
  amount: string;
  setAmount: Dispatch<SetStateAction<string>>;
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  max: string;
  handleBtnClick: () => Promise<void>;
  pendingTxn: boolean;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "250px",
};

export const InputContainer = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
`;

const StyledModal: FC<IStyledModal> = ({
  heading,
  open,
  handleClose,
  amount,
  setAmount,
  handleInputChange,
  max,
  handleBtnClick,
  pendingTxn,
}) => {
  const handleMaxClick = () => {
    setAmount(max);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Card
          sx={style}
          style={{ maxWidth: "500px", justifyContent: "space-between" }}
        >
          <CardHeading>{heading}</CardHeading>
          <LoadingButton
            variant={"contained"}
            fullWidth
            onClick={handleBtnClick}
            loading={pendingTxn}
          >
            {heading}
          </LoadingButton>
        </Card>
        <InputContainer>
          <TextField
            label={"Amount"}
            value={amount}
            placeholder={"Amount to enter"}
            variant={"outlined"}
            onChange={handleInputChange}
            size={"medium"}
            aria-placeholder={"0.0"}
            fullWidth
          />
          <Button
            variant="outlined"
            onClick={handleMaxClick}
            style={{
              position: "absolute",
              right: "10px",
              top: "15%",
            }}
          >
            Max
          </Button>
        </InputContainer>
      </>
    </Modal>
  );
};

export default StyledModal;
