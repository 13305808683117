import { useEffect, useState } from "react";
import { CardText } from "../styles/CardStyles";

const useCountdown = (targetDate: number) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export { useCountdown };

const CountdownTimer = ({ time }: { time: number }) => {
  const [days, hours, minutes, seconds] = useCountdown(time);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

const ExpiredNotice = () => {
  return <CardText>READY</CardText>;
};

const ShowCounter = ({
  days,
  hours,
  minutes,
  seconds,
}: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}) => {
  return (
    <>
      <DateTimeDisplay value={days} type={"Days"} />
      <CardText>:</CardText>
      <DateTimeDisplay value={hours} type={"Hours"} />
      <CardText>:</CardText>
      <DateTimeDisplay value={minutes} type={"Mins"} />
      <CardText>:</CardText>
      <DateTimeDisplay value={seconds} type={"Seconds"} />
    </>
  );
};

const DateTimeDisplay = ({ value, type }: { value: number; type: string }) => {
  return (
    <CardText>
      {value} {type}
    </CardText>
  );
};

export default CountdownTimer;
