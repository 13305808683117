import React, { useCallback, useEffect, useState } from "react";
import { INFINITE, STAKING_POOL_CONFIG } from "../../config";
import { Alert, Grid } from "@mui/material";
import HeroCard from "./HeroCard";
import { getERC20Contract, getPoolContract } from "../../utils/contractHelpers";
import StakingCard from "./StakingCard";
import RewardCard from "./RewardCard";
import styled from "styled-components";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import UpdateHarvestInterval from "../../components/UpdateHarvestInterval";
import RescueFunds from "../../components/RescueFunds";
import TransferRewardToken from "../../components/TransferRewardToken";
import Mint from "../../components/Mint";
import UpdateApr from "../../components/UpdateApr";
import BigNumber from "bignumber.js";
import { Erc20, Pool } from "../../config/abi/types";
import erc20Abi from "../../config/abi/erc20.json";
import { Contract } from "@ethersproject/contracts";
import { ethers } from "ethers";
import { Card } from "../../styles/CardStyles";
import whitelistedAddresses from "../../config/whitelistedAddresses";
import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
import UpdateMerkleRoot from "../../components/UpdateMerkleRoot";
import RewardTokensRemaining from "../../components/RewardTokensRemaining";

const StyledGrid = styled(Grid)`
  @media (max-width: 758px) {
    flex-wrap: wrap;
  }
`;

export const OwnerCard = styled(Card)`
  width: 100%;
  max-width: 500px;

  @media (max-width: 850px) {
    max-width: 100%;
  }
`;

export type IUserPoolData = null | {
  allowance: string;
  inputTokenBalance: string;
  amount: string;
  nextHarvestUntil: string;
  pendingReward: string;
  apr: string;
};

function hashToken(account: string) {
  return Buffer.from(
    ethers.utils.solidityKeccak256(["address"], [account]).slice(2),
    "hex"
  );
}

const PoolPage = ({
  handleConnectWalletModalOpen,
}: {
  handleConnectWalletModalOpen: () => void;
}) => {
  const { account, library } = useActiveWeb3React();
  const [pendingTxn, setPendingTxn] = useState(false);
  const [totalInputTokensStaked, setTotalInputTokensStaked] = useState("1");
  const [userPoolData, setUserPoolData] = useState<IUserPoolData>(null);
  const [ownerAddress, setOwnerAddress] = useState(STAKING_POOL_CONFIG.owner);

  let userIsWhitelisted = false;
  const hashData = whitelistedAddresses.map((address) => hashToken(address));
  const merkleTree = new MerkleTree(hashData, keccak256, { sortPairs: true });
  const merkleRoot = merkleTree.getHexRoot();
  let proof: string[] | [] = [];

  if (account) {
    proof = merkleTree.getHexProof(hashToken(account));
    userIsWhitelisted = merkleTree.verify(
      proof,
      hashToken(account),
      merkleTree.getHexRoot()
    );
  }

  const getTotalInputTokensStaked = useCallback(async () => {
    const poolContract = getPoolContract(STAKING_POOL_CONFIG.id, library);
    const inputTokensStaked = await poolContract.totalInputTokensStaked();
    setTotalInputTokensStaked(inputTokensStaked.toString());
  }, [library]);

  const getStakingPoolUserValues = useCallback(
    async (user: string) => {
      try {
        const poolContract = getPoolContract(
          STAKING_POOL_CONFIG.id,
          library
        ) as Pool;
        const erc20Contract = getERC20Contract(
          STAKING_POOL_CONFIG.inputToken.address,
          library
        );

        const allowance = await erc20Contract.allowance(
          user,
          STAKING_POOL_CONFIG.id
        );
        const inputTokenBalance = await erc20Contract.balanceOf(user);

        const { amount, nextHarvestUntil } = await poolContract.userInfo(user);
        const pendingReward = await poolContract.pendingReward(user, 0);
        const { expectedAPR } = await poolContract.rewardPool("0");
        const owner = await poolContract.owner();

        const rewardTokenRateInUSD = STAKING_POOL_CONFIG.rewardToken.price;
        const inputTokenRateInUSD = STAKING_POOL_CONFIG.inputToken.price;

        const calculatedAPR = new BigNumber(expectedAPR.toString())
          .multipliedBy(rewardTokenRateInUSD)
          .dividedBy(inputTokenRateInUSD)
          .dividedBy(new BigNumber(10).pow(18))
          .multipliedBy(100)
          .toFixed();

        setUserPoolData({
          allowance: allowance.toString(),
          inputTokenBalance: inputTokenBalance.toString(),
          amount: amount.toString(),
          nextHarvestUntil: nextHarvestUntil.toString(),
          pendingReward: pendingReward.toString(),
          apr: calculatedAPR.toString(),
        });
        setOwnerAddress(owner);
      } catch (e) {
        console.error("Error in getStakingPoolUserValues: ", e);
      }
    },
    [library]
  );

  const handleApprove = async () => {
    if (!account || !userPoolData) return;
    setPendingTxn(() => true);
    try {
      const approvalAmount = INFINITE;
      const provider = new ethers.providers.Web3Provider(
        // @ts-ignore
        (window as WindowChain).ethereum
      );
      const signer = provider.getSigner();
      const inputTokenContract = new Contract(
        STAKING_POOL_CONFIG.inputToken.address,
        erc20Abi,
        signer
      ) as Erc20;
      const approvalTx = await inputTokenContract.approve(
        STAKING_POOL_CONFIG.id,
        approvalAmount
      );
      await approvalTx.wait();
      const allowanceAmount = await inputTokenContract.allowance(
        account,
        STAKING_POOL_CONFIG.id
      );
      setUserPoolData({
        ...userPoolData,
        allowance: allowanceAmount.toString(),
      });
      setPendingTxn(() => false);
    } catch (error) {
      setPendingTxn(() => false);
      console.error("Error in handleApprove: ", error);
    }
  };

  useEffect(() => {
    getTotalInputTokensStaked();
    if (account) {
      getStakingPoolUserValues(account);

      const interval = setInterval(async () => {
        await getTotalInputTokensStaked();
        await getStakingPoolUserValues(account);
      }, 30000);

      return () => clearInterval(interval);
    }
  }, [account, getStakingPoolUserValues, getTotalInputTokensStaked]);

  return (
    <Grid container>
      {account && !userIsWhitelisted && (
        <Grid item xs={12} lg={12}>
          <Grid container>
            <Grid item xs={0} lg={3} />
            <Grid item xs={12} lg={6}>
              <Alert severity="error">You are not whitelisted</Alert>
            </Grid>
            <Grid item xs={0} lg={3} />
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} lg={12}>
        <Grid container>
          <Grid item xs={0} sm={0} md={2} lg={3}></Grid>
          <Grid item xs={12} sm={12} md={8} lg={6}>
            <HeroCard
              account={account}
              nextHarvest={
                userPoolData?.nextHarvestUntil
                  ? userPoolData.nextHarvestUntil
                  : null
              }
              totalInputTokensStaked={totalInputTokensStaked}
              apr={userPoolData?.apr ? userPoolData?.apr : null}
            />
          </Grid>
          <Grid item xs={0} sm={0} md={2} lg={3}></Grid>
        </Grid>
      </Grid>
      <StyledGrid
        item
        xs={12}
        lg={12}
        display={"flex"}
        justifyContent={"space-evenly"}
        alignItems={"center"}
        gap={3}
        marginTop={"20px"}
        flexWrap={"wrap"}
      >
        <StakingCard
          account={account}
          userPoolData={userPoolData}
          pendingTxn={pendingTxn}
          handleConnectWalletModalOpen={handleConnectWalletModalOpen}
          handleApprove={handleApprove}
          proof={proof}
          userIsWhitelisted={userIsWhitelisted}
        />
        <RewardCard
          account={account}
          userPoolData={userPoolData}
          pendingTxn={pendingTxn}
          setPendingTxn={setPendingTxn}
          getStakingPoolUserValues={getStakingPoolUserValues}
          handleConnectWalletModalOpen={handleConnectWalletModalOpen}
          proof={proof}
          userIsWhitelisted={userIsWhitelisted}
        />

        {account && account === ownerAddress && (
          <>
            <UpdateApr
              account={account}
              pendingTxn={pendingTxn}
              setPendingTxn={setPendingTxn}
              handleConnectWalletModalOpen={handleConnectWalletModalOpen}
              currExpectedAPR={userPoolData?.apr ?? "1"}
            />
            <UpdateHarvestInterval
              account={account}
              pendingTxn={pendingTxn}
              setPendingTxn={setPendingTxn}
              handleConnectWalletModalOpen={handleConnectWalletModalOpen}
            />
            {/*<MaxAllowedDeposit*/}
            {/*  account={account}*/}
            {/*  pendingTxn={pendingTxn}*/}
            {/*  setPendingTxn={setPendingTxn}*/}
            {/*  handleConnectWalletModalOpen={handleConnectWalletModalOpen}*/}
            {/*/>*/}
            <RescueFunds
              account={account}
              pendingTxn={pendingTxn}
              setPendingTxn={setPendingTxn}
              handleConnectWalletModalOpen={handleConnectWalletModalOpen}
              owner={ownerAddress}
            />
            <UpdateMerkleRoot
              account={account}
              pendingTxn={pendingTxn}
              setPendingTxn={setPendingTxn}
              handleConnectWalletModalOpen={handleConnectWalletModalOpen}
              merkleRoot={merkleRoot}
            />
            <Mint
              account={account}
              pendingTxn={pendingTxn}
              setPendingTxn={setPendingTxn}
              handleConnectWalletModalOpen={handleConnectWalletModalOpen}
              owner={ownerAddress}
            />
            <TransferRewardToken
              account={account}
              pendingTxn={pendingTxn}
              setPendingTxn={setPendingTxn}
              handleConnectWalletModalOpen={handleConnectWalletModalOpen}
            />
            <RewardTokensRemaining />
          </>
        )}
      </StyledGrid>
    </Grid>
  );
};

export default PoolPage;
